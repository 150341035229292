import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

window.runScroll = function(el, o) {

    let offsetTop = document.querySelector(el).offsetTop;

    if (o === undefined) {
        o = 40 + document.getElementsByClassName('js-topbar')[0].clientHeight;
    }

    scroll({
        top: offsetTop - o,
        behavior: "smooth"
    })
};

document.addEventListener('DOMContentLoaded',function() {

    const gtt = document.querySelectorAll("[data-target]");

    if (gtt.length > 0) {
        const action = function(e) {
        	e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
            let target = e.currentTarget.dataset.target,
                src = e.currentTarget.getAttribute('href'),
                offset = e.currentTarget.dataset.offset;

            for (let i = 0; i < gtt.length; i++) {
                gtt[i].classList.remove('is-active');
            }

            e.currentTarget.classList.add('is-active');

            document.getElementById(target.slice(1, target.length)) ? window.runScroll(target, offset) :
                window.open(src + target, '_self');
        };

        for (let i = 0; i < gtt.length; i++) {
            gtt[i].addEventListener('click', action);
        }
    }

}, false);
