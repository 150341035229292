import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination]);


document.addEventListener('DOMContentLoaded',function() {

    const yellowEl = document.querySelectorAll('.js-yellow');
    const teamsEl = document.querySelector('.js-teams');
    const benefitsEl = document.querySelector('.js-benefits');


    window.yellowCarousel = function() {
        let desktop = window.matchMedia('(min-width: 1266px)');
        const swiper = [];
        const init = [];


        yellowEl.forEach((el,index)=>{
            swiper[index] = null;//new Swiper(el,config);
            init[index] = false;

        });

        function initSwiper()
        {
            yellowEl.forEach((el,index)=>{
                const config = {
                    spaceBetween: 0,
                    navigation: {
                        nextEl: el.querySelector('.nextYellowBtn'),
                        prevEl: el.querySelector('.prevYellowBtn'),
                    },
                    speed: 800,
                    loop: true,
                    slidesPerView: 1,
                    slidesPerGroup: 1,

                };

                if (desktop.matches) {
                    if(swiper[index])
                        swiper[index].destroy();
                    init[index] = false;
                } else if (!init[index]) {
                    init[index] = true;
                    swiper[index] = new Swiper(el, config);
                }
            });
        }
        initSwiper();

        window.addEventListener('resize', function() {
            initSwiper();
        });
    };

    window.teamsCarousel = function() {
        let desktop = window.matchMedia('(min-width: 1266px)');

        const config = {
            spaceBetween: 0,
            navigation: {
                nextEl: '#nextTeamsBtn',
                prevEl: '#prevTeamsBtn',
            },
            speed: 800,
            loop: true,
            slidesPerView: 1,
            loopAdditionalSlides: 4,
            breakpoints: {
                979: {
                    slidesPerView: 3,
                    loopAdditionalSlides: 4
                },
                650: {
                    slidesPerView: 2,
                    loopAdditionalSlides: 4
                }
            }
        }

        let swiper = null;
        let init = false;

        function initSwiper()
        {
            if (desktop.matches) {
                if(swiper)
                    swiper.destroy();
                init = false;
            } else if (!init) {
                init = true;
                swiper = new Swiper(teamsEl, config);
            }
        }
        initSwiper();

        window.addEventListener('resize', function() {
            initSwiper();
        });
    };

    window.benefitsCarousel = function() {
        let desktop = window.matchMedia('(min-width: 820px)');

        const config = {
            spaceBetween: 0,
            navigation: {
                nextEl: '#nextBenefitsBtn',
                prevEl: '#prevBenefitsBtn',
            },
            speed: 800,
            loop: true,
            slidesPerView: 1,
            breakpoints: {
                600: {
                    slidesPerView: 2,
                    spaceBetween: 0
                }
            }
        }

        let swiper = null;
        let init = false;

        function initSwiper()
        {
            if (desktop.matches) {
                if(swiper)
                    swiper.destroy();
                init = false;
            } else if (!init) {
                init = true;
                swiper = new Swiper(benefitsEl, config);
            }
        }
        initSwiper();

        window.addEventListener('resize', function() {
            initSwiper();
        });
    };



    window.carousels = function() {
        yellowEl.length ? yellowCarousel(): false;
        teamsEl ? teamsCarousel(): false;
        benefitsEl ? benefitsCarousel(): false;
    }

}, false)
