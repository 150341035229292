import css from './sass/style.scss';

require('./js/anims.js');
require('./js/polyfills.js');
require('./js/fonts.js');
require('./js/carousels.js');
require('./js/recruitment.js');
require('./js/nav.js');
require('./js/helpers.js');
require('./js/init.js');
require('./js/inview.js');
require('./js/socials.js');
require('./js/cookies.js');
require('./js/smoothscroll.js')
