import axios from "axios";

document.addEventListener('DOMContentLoaded', function() {
    const API_ADDRESS = "https://api.poznaj.danone.pl";
    const API_TOKEN = "f6hjed-3sgbu-2sg65d-qdxu7k";

    const actions = [
        {
            el: document.getElementById('facebookPost'),
            endpoint: "/api/fb/feeds/1/" + API_TOKEN + "?limit=5",
            callback: (result, el) => renderMetaContent(result,el)
        },
        {
            el: document.getElementById('instagramPost'),
            endpoint: "/api/instagram/media/1/" + API_TOKEN + "?limit=1",
            callback: (result, el) => renderMetaContent(result,el)
        },
        {
            el: document.getElementById('wwwPost'),
            endpoint: "/api/news/" + API_TOKEN + "?limit=1",
            callback: (result, el) => {
                const aEl = document.createElement('a');
                const imgEl = document.createElement('img');

                aEl.href = result.url;
                aEl.target = "_blank";
                imgEl.src = result.image;
                aEl.append(imgEl);

                imgEl.onload = ()=>{
                    el.parentElement.querySelector('.c-social__loading').remove();
                    el.parentElement.classList.add('loaded');
                };

                el.append(aEl);
            }
        }
    ]

    //draw content from FB/Instagram
    function renderMetaContent(result,el){
        const aEl = document.createElement('a');
        const imgEl = document.createElement('img');

        aEl.href = result.permalink;
        aEl.target = "_blank";
        imgEl.src = result.image;
        aEl.append(imgEl);

        imgEl.onload = ()=>{
            el.parentElement.querySelector('.c-social__loading').remove();
            el.parentElement.classList.add('loaded');
        };

        el.append(aEl);
    }

    function downloadPost(action){
        axios.get(API_ADDRESS + action.endpoint)
            .then((result)=>{
                if(result.data && result.data[0])
                    action.callback(result.data[0], action.el);
            }).catch((error)=>{
                console.log(error.response)
        })
    }

    function init(){
        actions.forEach((action)=>{
            if(action.el){
                downloadPost(action);
            }
        })
    }

    init();

}, false);
