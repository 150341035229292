import { gsap, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded',function() {
    gsap.registerPlugin(ScrollTrigger);

    window.anims = function() {

        gsap.utils.toArray(".js-fadeInChildren > *").forEach(function(section) {
            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: '-10px bottom',
                    toggleActions: "play complete complete reset",
                },
                opacity: 0,
                duration: 1,
                y: 50
            });
        });

    };

}, false)
