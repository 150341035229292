(function() {

    const WebFont = require('webfontloader');

    WebFont.load({

        google: {
            families: ['Open+Sans:400,600:latin-ext']
        }
    });
})();

