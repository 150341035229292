import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', function() {

    const el = document.getElementById('cookies');
    const scripts = document.querySelectorAll('script[type="text/plain"]');

    const drawInlineScript = function (content){
        const scriptEl = document.createElement('script');
        scriptEl.type = 'text/javascript';
        scriptEl.textContent = content;
        document.body.append(scriptEl);
    }

    const drawHtml = function (content){
        const htmlEl = document.createElement('div');
        htmlEl.innerHTML = content;
        document.body.append(htmlEl);
    }

    const initScript = function() {

        scripts.forEach((script) => {
            const type = script.getAttribute('data-type');

            if (type == "js") {
                drawInlineScript(script.innerHTML);
                script.remove();
            } else if (type == 'html') {
                drawHtml(script.innerHTML);
                script.remove();
            }

        });
    }

    const init = function() {

        const accept = el.getElementsByClassName('js-accept')[0];
        const reject = el.getElementsByClassName('js-reject')[0];

        if(Cookies.get('magentateam') != 1 && Cookies.get('magentateam') != 2 ) {
            el.classList.remove('move-out') ;
            document.body.style.overflow = "hidden";
        }  else {
            document.body.style.overflow = "auto";
        }

        if(Cookies.get('magentateam') == 1)
            initScript();

        accept.addEventListener('click', function(e) {
            e.preventDefault();
            Cookies.set("magentateam", 1, { expires: 356, path: '/' });
            el.classList.add('move-out');
            document.body.style.overflow = "auto";
            initScript();
        });

        reject.addEventListener('click', function(e) {
            e.preventDefault();
            Cookies.set("magentateam", 2, { expires: 356, path: '/' });
            el.classList.add('move-out');
            document.body.style.overflow = "auto";
        });
    };


    el ? init() : false;

}, false);
