
document.addEventListener('DOMContentLoaded',function() {

    const items = document.querySelectorAll('.js-recruitment-item');
    if(!items.length)
        return

    let desktop = window.matchMedia('(min-width: 850px)');

    function init() {
        items.forEach((itemEl) => {
            itemEl.addEventListener('click',()=>{
                itemEl.parentElement.classList.toggle('toggled');
            })
        });
    }

    if (!desktop.matches) {
        init();
    }

});
