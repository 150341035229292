
document.addEventListener('DOMContentLoaded',function() {
    const menuBtn = document.getElementById('js-menu');
    const menuLinks = document.querySelectorAll('#js-nav a');

    if(!menuBtn)
        return;

    menuBtn.addEventListener('click',()=>{
        document.body.classList.toggle('is-nav-open');
    });

    if(menuLinks.length){
        menuLinks.forEach((linkEl)=>{
            linkEl.addEventListener('click',()=>{
                document.body.classList.remove('is-nav-open');
            })
        })
    }

}, false);
