import InView from 'inview';

document.addEventListener('DOMContentLoaded',function() {

    const cover = document.getElementById('cover');

    // inview: show visible elements without anim
    const anims = document.getElementsByClassName('anim');
    if (anims.length > 0) {
        for (let i = 0; i < anims.length; i++) {
            if (cutme.Helpers.isInView(anims[i])) {
                anims[i].classList.add('anim--visible');
            }
        }
    }

    const init = function() {
        document.documentElement.removeAttribute('style');
        document.documentElement.classList.add('is-loaded');

        setTimeout(function() {
//            window.carousels();
            window.inviewInit();
            cover.remove();
        }, 250);


        window.carousels()

    };

    window.addEventListener('load', init);


}, false);
